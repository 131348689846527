import React from "react";
import ReadMore from "../readmore";

interface BlogProps {
  text: string;
  subText: string;
  imagesrc: string;
  redirectsrc: string;
  readmoretitle: string;
  small?: boolean;
}

const BlogCard = ({
  text,
  subText,
  imagesrc,
  redirectsrc,
  readmoretitle,
  small,
}: BlogProps) => {
  return (
    <div className="relative shadow-sm sm:rounded-xl px-5 bg-[#F5F7F8]  flex flex-col ">
      <div className="flex flex-col mt-6  justify-center text-gray-700 bg-white shadow-sm border rounded-xl lg:px-0 mx-0">
        <img src={imagesrc} alt="blog.img" />
      </div>
      <p
        className={`font-[300] text-[0.5rem] ${small ? "lg:text-[0.67rem]" : "lg:text-[0.7rem]"} leading-[3rem] tracking-wider lg:px-2 mt-1 uppercase`}
      >
        {subText}
      </p>
      <p
        className={`font-[600] ${small ? "text-[0.8rem] lg:text-[0.87rem]" : "text-[0.9rem] lg:text-[1.5rem]"} leading-[3rem] tracking-wider lg:px-2`}
      >
        {text}
      </p>
      <div className="self-end flex flex-row mb-2">
        <ReadMore redirect={redirectsrc} title={readmoretitle} />
      </div>
    </div>
  );
};

export default BlogCard;
