import React, { useState } from "react";
import AnyMtlxPopOver from "../anymtlxPopover";
import Header from "../Header";
import Footer from "../footer";

const Faq = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const faqs = [
    {
      title: <span className="text-lg font-bold">What is Mettalex?</span>,
      description: (
        <span>
          Mettalex is the world's first peer-to-peer (P2P) order book and AI
          agents-based decentralized exchange (DEX).
        </span>
      ),
    },
    {
      title: (
        <span className="text-lg font-bold">
          What is the vision of Mettalex?
        </span>
      ),
      description: (
        <span>
          Empowering seamless, direct, and equitable trading across the
          (decentralized) financial landscape, Mettalex is dedicated to
          eliminating traditional barriers in DeFi through innovative,
          agent-based technology. We envision a world where every trader has
          frictionless, fair access to global markets without the complexities
          of traditional liquidity pools and the inefficiencies they bring.
        </span>
      ),
    },
    {
      title: (
        <span className="text-lg font-bold">
          What is the mission of Mettalex?
        </span>
      ),
      description: (
        <span>
          To democratize access to global trading markets by providing a secure,
          transparent, and efficient decentralized trading platform. Mettalex
          aims to empower traders of all sizes with peer-to-peer direct trading
          capabilities enhanced by advanced AI-agent technology, ensuring zero
          slippage and broader market accessibility without the traditional
          barriers of liquidity pools. Our mission is to innovate within the
          DeFi space, enabling seamless, cross-chain transactions and fostering
          a fair and inclusive financial ecosystem for everyone.
        </span>
      ),
    },
    {
      title: (
        <span className="text-lg font-bold">
          What makes Mettalex unique from other DEXs?
        </span>
      ),
      description: (
        <span>
          Mettalex stands out in the decentralized finance (DeFi) space for
          several key reasons that differentiate it from other platforms:
        </span>
      ),
      list: [
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              Peer-to-Peer Trading without Liquidity Pools:
            </span>
          ),
          unOrderListDescription: (
            <span>
              Unlike traditional DeFi platforms that rely on liquidity pools,
              Mettalex allows users to trade directly with one another. This
              peer-to-peer trading model, powered by an AI-agent-based order
              book, eliminates the need for intermediary liquidity providers,
              reducing complexity and the potential for slippage.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">Zero Slippage:</span>
          ),
          unOrderListDescription: (
            <span>
              Mettalex ensures that trades are executed at the displayed price
              with no deviation. This zero slippage guarantee is a significant
              advantage, particularly in volatile market conditions where other
              platforms might see prices change unfavorably between the time an
              order is placed and executed.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              AI-Agent Technology:
            </span>
          ),
          unOrderListDescription: (
            <span>
              The platform leverages Fetch.ai’s AI-agent technology to
              facilitate order matching and trade execution. These agents
              operate autonomously to match buyers and sellers efficiently,
              ensuring faster and more reliable trades.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              Cross-Chain and Cross-Asset Trading:
            </span>
          ),
          unOrderListDescription: (
            <span>
              Mettalex supports trading across different blockchain ecosystems
              and various asset types, including cryptocurrencies, commodities,
              and tokenized real-world assets.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              Inclusive Trading Ecosystem:
            </span>
          ),
          unOrderListDescription: (
            <span>
              By simplifying the trading process and removing the need for large
              capital outlays to participate in liquidity pools, Mettalex
              democratizes access to DeFi.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              Enhanced Security and Reduced Costs:
            </span>
          ),
          unOrderListDescription: (
            <span>
              By eliminating the need for multiple asset transfers between
              different liquidity pools, Mettalex reduces transaction fees (gas
              fees) and exposure to potential security risks associated with
              smart contract vulnerabilities.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              Empowering Smaller Traders:
            </span>
          ),
          unOrderListDescription: (
            <span>
              The platform's design inherently supports smaller traders by
              allowing them to participate in DeFi with lower entry barriers.
            </span>
          ),
        },
      ],
    },
    {
      title: (
        <span className="text-lg font-bold">
          Who is the founder and CEO of Mettalex?
        </span>
      ),
      description: (
        <span>
          The founder and CEO of Mettalex is Humayun Sheikh. He is an
          entrepreneur, investor, and tech visionary with a passion for
          technologies such as AI, machine learning, autonomous agents, and
          blockchains. Humayun was a founding investor in DeepMind, where he
          supported the commercialization of early-stage AI and deep neural
          network technology. He is currently leading Fetch.ai as CEO.
          Additionally, he serves as the chairman of the Artificial
          Superintelligence Alliance. Humayun is an expert on the topics of
          artificial intelligence, machine learning, autonomous agents, and the
          intersection of blockchain and commodities.
        </span>
      ),
    },
    {
      title: (
        <span className="text-lg font-bold">
          What{" "}
          <a
            className="text-blue-800"
            rel="noreferrer"
            target="_blank"
            href="https://fetch.ai/"
          >
            Fetch.ai
          </a>{" "}
          technology is used on the Mettalex platform?
        </span>
      ),
      description: (
        <span>
          The Mettalex platform utilizes several technologies from{" "}
          <a
            className="text-blue-800"
            rel="noreferrer"
            target="_blank"
            href="https://fetch.ai/"
          >
            Fetch.ai
          </a>{" "}
          :
        </span>
      ),
      list: [
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              Fetch Agent Technology
            </span>
          ),
          unOrderListDescription: (
            <span>
              to form an Autonomous AI Agents network to execute trades on
              behalf of users.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">Fetch Network</span>
          ),
          unOrderListDescription: (
            <span>
              where Agents need to register on this network for seamless search
              & discovery, ensuring your trades are timely and effective.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">Fetch Wallet</span>
          ),
          unOrderListDescription: (
            <span>
              allows users to connect with the Fetch network and other
              Cosmos-SDK ecosystem blockchains, supporting the IBC protocol for
              diverse interactions.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">The Cosmpy</span>
          ),
          unOrderListDescription: (
            <span>
              helps Fetch agents interact with the blockchain, enabling tasks
              like reading data and executing transactions.
            </span>
          ),
        },
      ],
    },
    {
      title: <span className="text-lg font-bold">How does Mettalex work?</span>,
      description: (
        <span>
          Mettalex is a decentralized exchange (DEX) that facilitates direct
          peer-to-peer (P2P) trading without traditional liquidity pools. It
          uses an order book system to match buy and sell orders directly
          between users through AI Agents. For more detailed information, please{" "}
          <a
            className="text-blue-800"
            rel="noreferrer"
            target="_blank"
            href="https://docs.mettalex.com/docs/TechnicalArchitecture/InitializingYourAccount"
          >
            refer to the documentation.
          </a>{" "}
        </span>
      ),
    },
    {
      title: (
        <span className="text-black text-lg font-bold">
          What is the role of Agents in Mettalex?
        </span>
      ),
      description: (
        <span>
          Agents are at the core of Mettalex’s functionality, leveraging
          artificial intelligence to automate and optimize trading processes.
          Mettalex uses AI-powered agents to streamline the trading process on
          its platform, making it faster, more efficient, and accessible to a
          broader audience.
        </span>
      ),
      list: [
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              Order Matching:
            </span>
          ),
          unOrderListDescription: (
            <span>
              Agents use AI algorithms to match buy and sell orders efficiently.
              This system ensures that trades are executed promptly and at the
              best possible prices, without the traders needing to manually
              search for matches.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              Trade Execution:
            </span>
          ),
          unOrderListDescription: (
            <span>
              Once a match is found, agents facilitate the trade execution,
              handling the complexities of ensuring that all conditions of the
              trade are met and that the transaction is securely processed on
              the blockchain.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              Autonomous Trading Strategies:
            </span>
          ),
          unOrderListDescription: (
            <span>
              Agents can also assist users in implementing trading strategies.
              They operate continuously, scanning the market for opportunities
              and executing trades based on predefined criteria set by the user.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              Cross-Chain Interactions:
            </span>
          ),
          unOrderListDescription: (
            <span>
              Agents help manage the complexities of cross-chain trading by
              interfacing with different blockchain protocols. This
              functionality simplifies the process for users, who can trade
              across chains without worrying about the technical intricacies.
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              Security and Compliance:
            </span>
          ),
          unOrderListDescription: (
            <span>
              Agents monitor transactions for unusual activity to ensure
              compliance with regulatory requirements and to maintain the
              security and integrity of the trading platform.
            </span>
          ),
        },
      ],
      regards: (
        <span>
          For more detailed information, please{" "}
          <a
            className="text-blue-800"
            rel="noreferrer"
            target="_blank"
            href="https://docs.mettalex.com/docs/mtlxtoken/mtlxtokenutility/"
          >
            refer to the documentation.
          </a>{" "}
        </span>
      ),
    },
    {
      title: (
        <span className="text-lg font-bold">
          What digital assets are traded and on which blockchain in the Mettalex
          Platform?
        </span>
      ),
      description: (
        <span>
          Mettalex will support trading of various tokenized digital assets
          across different blockchain ecosystems.Please refer to the{" "}
          <a
            className="text-blue-800"
            rel="noreferrer"
            target="_blank"
            href="https://docs.mettalex.com/resources/roadmap/"
          >
            roadmap
          </a>{" "}
          for more Info.
        </span>
      ),
    },
    {
      title: (
        <span className="text-lg font-bold">
          When will additional digital assets and blockchains be integrated into
          the Mettalex platform?
        </span>
      ),
      description: (
        <span>
          Currently, the Mettalex private beta platform supports trading of FET,
          USDT on the testnet. Plans are in place to onboard additional digital
          assets across various blockchains. Please refer to the{" "}
          <a
            className="text-blue-800"
            rel="noreferrer"
            target="_blank"
            href="https://docs.mettalex.com/resources/roadmap/"
          >
            roadmap
          </a>{" "}
          for more Info.
        </span>
      ),
    },
    {
      title: <span className="text-lg font-bold">What is $MTLX?</span>,
      description: (
        <span>
          $MTLX is the native token of the Mettalex platform, used for various
          utilities within the ecosystem. For more information, Please{" "}
          <a
            className="text-blue-800"
            rel="noreferrer"
            target="_blank"
            href="https://docs.mettalex.com/docs/mtlxtoken/mtlxtokenutility/"
          >
            refer to the documentation.
          </a>{" "}
        </span>
      ),
    },
    {
      title: (
        <span className="text-lg font-bold">
          What are the Utilities of the $MTLX token?
        </span>
      ),
      description: (
        <span>
          $MTLX token will be used for trading fee discounts, staking,
          governance, and accessing premium features on the Mettalex platform.
          For more detailed information, please{" "}
          <a
            className="text-blue-800"
            rel="noreferrer"
            target="_blank"
            href="https://docs.mettalex.com/docs/mtlxtoken/mtlxtokenutility/"
          >
            refer to the documentation.
          </a>{" "}
        </span>
      ),
    },
    {
      title: (
        <span className="text-lg font-bold">Where can I Trade $MTLX?</span>
      ),
      description: <span>You can trade $MTLX on:</span>,
      list: [
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              Uniswap (Ethereum):
            </span>
          ),
          unOrderListDescription: (
            <span>
              {" "}
              <a
                className="text-blue-800"
                rel="noreferrer"
                target="_blank"
                href="https://app.uniswap.org/explore/tokens/ethereum/0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14"
              >
                $MTLX.
              </a>{" "}
            </span>
          ),
        },
        {
          unOrderListTitle: (
            <span className="text-black text-md font-bold">
              PancakeSwap (BSC):
            </span>
          ),
          unOrderListDescription: (
            <span>
              {" "}
              <a
                className="text-blue-800"
                rel="noreferrer"
                target="_blank"
                href="https://pancakeswap.finance/swap?outputCurrency=0x5921DEE8556c4593EeFCFad3CA5e2f618606483b&chainId=56&inputCurrency=0x55d398326f99059fF775485246999027B3197955"
              >
                $anyMTLX.
              </a>{" "}
            </span>
          ),
        },
      ],
    },
    {
      title: (
        <span className="text-lg font-bold">
          How do I contact the Mettalex team for inquiries?
        </span>
      ),
      description: (
        <span>
          You can contact the Mettalex team by emailing{" "}
          <a
            className="text-blue-800"
            rel="noreferrer"
            href="mailto:info@mettalex.com"
          >
            info@mettalex.com
          </a>
          .
        </span>
      ),
    },
  ];

  return (
    <div className="h-screen">
      {open && <AnyMtlxPopOver open={open} handleClose={handleClose} />}
      <Header setOpen={setOpen} />
      <div className="flex space-x-6 justify-center w-full">
        <p className="text-center mt-[7rem] text-indigo-600 text-5xl tracking-wider leading-[4rem] font-bold">
          FREQUENTLY ASKED QUESTIONS
        </p>
      </div>
      <section
        id="faqs"
        className="py-20 relative  max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8"
      >
        <div className="space-y-4">
          {faqs.map((item, idx) => (
            <details
              className="group [&_summary::-webkit-details-marker]:hidden"
              key={idx}
            >
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900 shadow-xl hover:bg-gradient-to-r hover:from-violet-300 hover:to-violet-50 active:bg-gradient-to-r active:from-red-50 active:to-sky-50">
                <h2 className="font-medium">{item.title}</h2>

                <svg
                  className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>

              <p className="mx-2 my-2 px-4 leading-relaxed  text-gray-700 rounded-lg">
                {item.description}
              </p>
              <div className="mx-2 my-2 px-4 leading-relaxed  text-gray-700 rounded-lg">
                {item?.list?.map((listItem, index) => (
                  <li>
                    {listItem.unOrderListTitle}{" "}
                    {listItem.unOrderListDescription}
                  </li>
                ))}
              </div>
              {item?.regards && (
                <p className="mx-2 my-2 px-4 leading-relaxed  text-gray-700 rounded-lg">
                  {item?.regards}
                </p>
              )}
            </details>
          ))}
        </div>
      </section>
      <div className="relative mt-[5rem]">
        <img
          className="w-[100%] h-[45rem] bottom-0 absolute z-[-9999] md:flex hidden"
          src="images/foot.png"
          alt="headerimage"
        />
        <Footer />
      </div>
    </div>
  );
};

export default Faq;
