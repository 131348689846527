import React, { useState } from "react";

interface ReadMoreProps {
  redirect: string;
  title: string;
}

const ReadMore = ({ redirect, title }: ReadMoreProps) => {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  const handleMenuItemHover = (index: number) => {
    setHoveredItem(index);
  };

  const handleMenuItemLeave = () => {
    setHoveredItem(null);
  };

  
  return (
    <>
      <a
        target="_Blank"
        href={redirect}
        className={`w-full flex tracking-wider items-center flex-row px-2 lg:px-1  text-sm  font-semibold leading-[22px] md:px-3 text-black hover:text-indigo-500 `}
        rel="noreferrer"
        onMouseEnter={() => handleMenuItemHover(1)}
        onMouseLeave={handleMenuItemLeave}
      >
        <p>{title}</p>
        <div>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform="rotate(-45)"
            height={27}
            width={27}
            className={`ml-1 mb-1 ${
              hoveredItem &&
              "rotate-[40] transition-transform duration-700 ease-out"
            } ${hoveredItem === null && "transition-transform duration-700 ease-out"}`}
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M6 12H18M18 12L13 7M18 12L13 17"
                stroke="#7b56f5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
            </g>
          </svg>
        </div>
      </a>
    </>
  );
};

export default ReadMore;
