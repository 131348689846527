import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../footer";
import TeamCarousel from "./teamCarousel";
import { useState } from "react";
import AnyMtlxPopOver from "../anymtlxPopover";

const TeamSection = () => {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuItemHover = (index: number) => {
    setHoveredItem(index);
  };

  const handleMenuItemLeave = () => {
    setHoveredItem(null);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 500,
      behavior: "smooth",
    });
  };

  return (
    <div className="">
      <div className="relative">
        <img
          className=" w-full md:h-[37rem] lg:h-[55rem] absolute z-[-9999] md:flex hidden"
          src="images/Top2.png"
          alt="headerimage"
        />
        {/* <Proposel /> */}
        {open && <AnyMtlxPopOver open={open} handleClose={handleClose} />}

        <Header setOpen={setOpen} />
      </div>

      <div className="w-screen xl:w-[95%] xl:space-y-16  flex flex-col mx-auto md:mb-[2rem] xl:mb-[5.5rem] ">
        <div
          className={`flex  py-7 mt-[8rem] xl:ml-[5rem] justify-around transition ease-in-out duration-500 md:px-4 lg:px-10 flex-col md:flex-row items-center`}
        >
          <div className="flex w-[50%] px-5 lg:px-0 md:w-[35%] xl:w-[40%] justify-center">
            <img
              src="images/Aboutus.png"
              alt="productImage"
              height={600}
              width={700}
              className="md:mr-20"
            />
          </div>
          <div
            className={`flex flex-col lg:mt-[1rem] w-[90%] md:w-[90%] xl:w-[60%] lg:ml-[0rem] 2xl:ml-[10rem] `}
          >
            <div className="flex flex-col space-y-4">
              {/* <div>
                <p className="mx-2 p-2 text-center md:text-start  text-[2rem]   lg:text-start lg:text-[3.2rem] font-bold text-black tracking-wide leading-[3rem] lg:leading-[4rem]">
                  About
                </p>
                <p
                  className={`text-gray-800 text-justify md:text-start flex-wrap mx-3 px-2 lg:text-lg tracking-wide leading-7 lg:leading-8  mt-4 xl:w-[72%]`}
                >
                  Welcome to Mettalex, a decentralized exchange (DEX) powered by
                  autonomous agents. At the heart of our mission is the
                  transformation of the DeFi landscape through our unique
                  peer-to-peer (P2P) orderbook-based trading platform, designed
                  to offer secure, efficient, and user-centric trading
                  experiences.
                </p>
              </div> */}
              <div>
                <p className="mx-2 p-2 text-center md:text-start  text-[2rem]   lg:text-start lg:text-[3.2rem] font-bold text-black tracking-wide leading-[3rem] lg:leading-[4rem]">
                  Our Mission
                </p>

                <p
                  className={`text-gray-800 text-justify md:text-start flex-wrap mx-3 px-2 lg:text-lg tracking-wide leading-7 lg:leading-8  mt-4 xl:w-[72%]`}
                >
                  To democratize access to global trading markets by providing a
                  secure, transparent, and efficient decentralized trading
                  platform. Mettalex aims to empower traders of all sizes with
                  peer-to-peer direct trading capabilities enhanced by advanced
                  AI-agent technology, ensuring zero slippage and broader market
                  accessibility without the traditional barriers of liquidity
                  pools. Our mission is to innovate within the DeFi space,
                  enabling seamless, cross-chain transactions and fostering a
                  fair and inclusive financial ecosystem for everyone.
                </p>
              </div>
              <div>
                <p className="mx-2 p-2 text-center md:text-start  text-[2rem]   lg:text-start lg:text-[3.2rem] font-bold text-black tracking-wide leading-[3rem] lg:leading-[4rem]">
                  Our Vision
                </p>

                <p
                  className={`text-gray-800 text-justify md:text-start flex-wrap mx-3 px-2 lg:text-lg tracking-wide leading-7 lg:leading-8  mt-4 xl:w-[72%]`}
                >
                  Empowering seamless, direct, and equitable trading across the
                  (decentralized) financial landscape, Mettalex is dedicated to
                  eliminating traditional barriers in DeFi through innovative,
                  agent-based technology. We envision a world where every trader
                  has frictionless, fair access to global markets without
                  the complexities of traditional liquidity pools and the
                  inefficiencies they bring.
                </p>
              </div>
            </div>

            <div className="flex flex-row space-x-4 px-4 mt-12 justify-start">
              <Link
                className=" sm:text-base text-sm py-3 px-3 sm:px-7 inline-flex items-center gap-x-2  font-semibold rounded-lg border border-transparent bg-indigo-800 text-white hover:bg-indigo-600 disabled:opacity-50 disabled:pointer-events-none  transition-all ease-in-out duration-500 tracking-widest"
                rel="noreferrer"
                to="/about"
                onClick={handleScrollToTop}
              >
                Meet the Team
              </Link>
              <Link
                to="https://wellfound.com/company/mettalex_dex"
                type="button"
                onMouseEnter={() => handleMenuItemHover(1)}
                target="_blank"
                onMouseLeave={handleMenuItemLeave}
                className=" md:text-base text-sm py-3 px-3 sm:px-7 inline-flex items-center gap-x-2 font-semibold rounded-lg border border-transparent bg-indigo-800 text-white hover:bg-indigo-600 disabled:opacity-50 disabled:pointer-events-none transition-all ease-in-out duration-500 tracking-widest"
              >
                Join us
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  transform="rotate(-45)"
                  height={27}
                  width={27}
                  className={`ml-1 mb-1 ${
                    hoveredItem &&
                    "rotate-[40] transition-transform duration-700 ease-out"
                  } ${hoveredItem === null && "transition-transform duration-700 ease-out"}`}
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M6 12H18M18 12L13 7M18 12L13 17"
                      stroke="#ffff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="w-screen xl:w-[95%]  flex flex-row mx-auto md:mb-[2rem] xl:mb-[5.5rem] ">
        <div
          className={`flex w-full py-7 justify-around transition ease-in-out duration-500 md:px-4 lg:px-10 flex-col md:flex-row-reverse items-center`}
        >
          {/* <div className="flex w-[50%] px-5 lg:px-0 md:w-[45%] xl:w-[50%] justify-center">
            <img
              src="images/team.png"
              alt="productImage"
              height={450}
              width={580}
              className="md:mr-20"
            />
          </div> */}
          <div
            className={`flex flex-col lg:mt-[1rem] w-[90%] md:w-[90%] xl:w-[50%] lg:ml-[0rem] 2xl:ml-[10rem] justify-center items-center  `}
          >
            <p className="text-black text-center md:text-start font-[1000] text-[2.2rem] lg:text-[4rem] 2xl:text-[5rem] leading-[3rem] tracking-wider lg:leading-[7.5rem] lg:tracking-wide ">
              Mettalex Team
            </p>
            {/* <p
              className={`text-gray-800 text-center md:text-start flex-wrap mx-3 px-2 lg:text-lg tracking-wide leading-7 lg:leading-8  mt-4 md:w-[72%]`}
            >
              Discover the driven team of professionals, engineers, and
              visionaries propelling our platform into the future of
              decentralized finance.
            </p> */}
          </div>
        </div>
      </div>
      {/* <div className="relative z-[1] mt-[36px] md:mt-[84px]"> */}
      {/* <div className="try absolute left-0 top-[-0.5rem] z-[999] w-[10%] h-[90%]  md:backdrop-filter md:backdrop-blur-[1px]" />
        <div className="try1 absolute right-0 top-0 z-[999] w-[10%] h-[90%]  md:backdrop-filter md:backdrop-blur-[1px]" /> */}
      <TeamCarousel />
      {/* </div> */}

      <div className="relative mt-[8rem]">
        <img
          className="w-[100%] h-[45rem]  bottom-0 absolute z-[-9999] md:flex hidden"
          src="images/foot.png"
          alt="headerimage"
        />
        <Footer />
      </div>
    </div>
  );
};

export default TeamSection;
