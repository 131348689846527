export const CHAIN_CONFIG = {
  chainId: "dorado-1",
  chainName: "Dorado",
  coinName: "TESTFET",
  rpcEndpoint: "https://rpc-dorado.fetch.ai:443",
  restEndpoint: "https://rest-dorado.fetch.ai:443",
  contractAddress:
    "fetch1km2yj8eqwetxua05uy9yxaa45x6skzsg65hjfyp3ummwe6sx840qlr4jeu",
  stakingDenom: "atestfet",
  gasPrice: "5000000000",
};

export function defaultBech32Config(
  mainPrefix: any,
  validatorPrefix: any,
  consensusPrefix: any,
  publicPrefix: any,
  operatorPrefix: any
) {
  return {
    bech32PrefixAccAddr: mainPrefix,
    bech32PrefixAccPub: mainPrefix + publicPrefix,
    bech32PrefixValAddr: mainPrefix + validatorPrefix + operatorPrefix,
    bech32PrefixValPub:
      mainPrefix + validatorPrefix + operatorPrefix + publicPrefix,
    bech32PrefixConsAddr: mainPrefix + validatorPrefix + consensusPrefix,
    bech32PrefixConsPub:
      mainPrefix + validatorPrefix + consensusPrefix + publicPrefix,
  };
}

export const POLLING_INTERVAL_MS = 10000;
export const CHAIN_ID = CHAIN_CONFIG.chainId;
export const CHAIN_NAME = CHAIN_CONFIG.chainName;
export const RPC_ENDPOINT = CHAIN_CONFIG.rpcEndpoint;
export const REST_ENDPOINT = CHAIN_CONFIG.restEndpoint;
export const CONTRACT_ADDRESS = CHAIN_CONFIG.contractAddress;
export const COIN_NAME = CHAIN_CONFIG.coinName;
export const STAKING_DENOM = CHAIN_CONFIG.stakingDenom;
export const GAS_PRICE = CHAIN_CONFIG.gasPrice;

export const KEPLR_CHAIN_CONFIG = {
  alternativeBIP44s: [],
  bech32Config: defaultBech32Config(
    "fetch",
    "fetch",
    "fetch",
    "fetch",
    "fetch"
  ),
  beta: false,
  bip44: {
    coinType: 118,
  },
  chainId: CHAIN_ID,
  chainName: CHAIN_NAME,
  coinType: 118,
  currencies: [
    {
      coinDenom: COIN_NAME,
      coinMinimalDenom: STAKING_DENOM,
      coinDecimals: 18,
    },
  ],
  features: [],
  feeCurrencies: [
    {
      coinDenom: COIN_NAME,
      coinMinimalDenom: STAKING_DENOM,
      coinDecimals: 18,
    },
  ],
  gasPriceStep: {
    low: 0,
    average: 5000000000,
    high: 6250000000,
  },
  rest: REST_ENDPOINT,
  rpc: RPC_ENDPOINT,
  stakeCurrency: {
    coinDenom: COIN_NAME,
    coinMinimalDenom: STAKING_DENOM,
    coinDecimals: 18,
    coinGeckoId: "fetch-ai",
  },
};
