import React from "react";

const NotFound = () => {
  return (
    <div className="bg-gradient-to-r from-[#9379E7] via-[60%] to-[#FFFFFF] h-[100vh] w-screen bg-white text-indigo-600 flex justify-center items-center font-extrabold text-[5rem]">
      404 - Page Not Found
    </div>
  );
};

export default NotFound;
