import { BrowserRouter, Routes, Route } from "react-router-dom";
// import BlogsSection from "./component/blogssection";
import TeamSection from "./component/team";
// import CommunitySection from "./component/communitysection";
import Home from "./home";
import NotFound from "./component/notfound";
import EarlyAccess from "./earlyaccess";
import { NetworkContext } from "./component/walletConnect";
import PrivacyPolicy from "./component/privacypolicy";
import TermsOfCondition from "./component/termsofcondition";
import Faq from "./component/faq";
// import Career from "./component/careersection";
// import Aboutus from "./component/aboutus";

function App() {
  const [initialWalletState, connectKeplr] = NetworkContext();

  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          {/* <Route path="blogs" element={<BlogsSection />} /> */}
          {/* <Route path="community" element={<CommunitySection />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<TeamSection />} />
          {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-condition" element={<TermsOfCondition />} /> */}
          <Route
            path="/earlyaccess"
            element={
              <EarlyAccess
                initialWalletState={initialWalletState}
                connectKeplr={connectKeplr}
              />
            }
          />{" "}
          <Route path="/faq" element={<Faq />} />
          {/* <Route path="/careers" element={<Career />} /> */}
          {/* <Route path="/aboutus" element={<Aboutus />} /> */}
          <Route path="*" element={<NotFound />} />{" "}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
