import React from "react";
import TeamCards from "./teamCards";
import { socialProof } from "../../data";


const TeamCarousel = () => {
  // const settings = {
  //   infinite: true,
  //   speed: 700,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   centeMode: true,
  //   centerPadding: "60px",
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 3650,
  //       settings: {
  //         speed: 500,
  //         initialSlide: 2,
  //         slidesToShow: 8,
  //       },
  //     },
  //     {
  //       breakpoint: 3290,
  //       settings: {
  //         speed: 500,
  //         initialSlide: 2,
  //         slidesToShow: 6.5,
  //       },
  //     },
  //     {
  //       breakpoint: 2790,
  //       settings: {
  //         speed: 500,
  //         initialSlide: 2,
  //         slidesToShow: 5.7,
  //       },
  //     },
  //     {
  //       breakpoint: 2380,
  //       settings: {
  //         speed: 500,
  //         initialSlide: 2,
  //         slidesToShow: 5,
  //       },
  //     },
  //     {
  //       breakpoint: 2080,
  //       settings: {
  //         speed: 500,
  //         initialSlide: 2,
  //         slidesToShow: 4.5,
  //       },
  //     },
  //     {
  //       breakpoint: 1900,
  //       settings: {
  //         speed: 500,
  //         initialSlide: 2,
  //         slidesToShow: 3.8,
  //       },
  //     },
  //     {
  //       breakpoint: 1750,
  //       settings: {
  //         speed: 500,
  //         initialSlide: 2,
  //         slidesToShow: 3.8,
  //       },
  //     },
  //     {
  //       breakpoint: 1550,
  //       settings: {
  //         speed: 500,
  //         slidesToShow: 3.5,
  //       },
  //     },
  //     {
  //       breakpoint: 1405,
  //       settings: {
  //         speed: 500,
  //         slidesToShow: 3.3,
  //       },
  //     },
  //     {
  //       breakpoint: 1325,
  //       settings: {
  //         speed: 500,
  //         slidesToShow: 3.2,
  //       },
  //     },
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         speed: 500,
  //         centerPadding: "60px",
  //         slidesToShow: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         speed: 1000,
  //         slidesToShow: 2.5,
  //         centerMode: true,
  //       },
  //     },
  //     {
  //       breakpoint: 856,
  //       settings: {
  //         speed: 1000,
  //         slidesToShow: 2,
  //         centerPadding: "50px",
  //         centerMode: true,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 2,
  //         centerMode: true,
  //       },
  //     },
  //     {
  //       breakpoint: 636,
  //       settings: {
  //         slidesToShow: 1.5,
  //         centerMode: true,
  //       },
  //     },
  //     {
  //       breakpoint: 554,
  //       settings: {
  //         speed: 1000,
  //         slidesToShow: 1.1,
  //         centerPadding: "30px",
  //         centerMode: true,
  //       },
  //     },
  //     {
  //       breakpoint: 450,
  //       settings: {
  //         speed: 1000,
  //         slidesToShow: 1,
  //         centerPadding: "20px",
  //         centerMode: true,
  //       },
  //     },
  //   ],
  // };
  return (
    <>
      {/* <Slider arrows={false} ref={sliderReference} {...settings} centerMode> */}

      <div
        className={`grid md:grid-cols-3 2xl:grid-cols-3 gap-y-[2rem] md:gap-y-[2rem] xl:gap-y-[2rem] gap-x-[10rem] 3xl:gap-x-[4rem] w-[95%] lg:w-[90%] xl:w-[95%] 2xl:w-[73.5%] mx-auto place-items-center`}
      >
        {socialProof.map((item, index) => (
          <TeamCards
            key={index}
            name={item.name}
            position={item.position}
            comment={item.comment}
            imgsrc={item.image}
            telegram={item.telegram}
            github={item.github}
            twitter={item.twitter}
            linkedin={item.linkedin}
          />
        ))}
      </div>

      {/* </Slider> */}
      {/* <div className="flex items-center justify-center gap-2 py-12">
        <img
          src="images/prev.png"
          alt="prev-img"
          width={50}
          height={50}
          className="z-10 cursor-pointer bg-indigo-800 rounded-full"
          onClick={() => sliderReference?.current?.slickPrev()}
        />
        <img
          onClick={() => sliderReference?.current?.slickNext()}
          src="images/next.png"
          alt="next-img"
          width={50}
          height={50}
          className="z-10 cursor-pointer bg-indigo-800 rounded-full"
        />
      </div> */}
    </>
  );
};

export default TeamCarousel;
