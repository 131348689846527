import React, { useEffect, useState } from "react";
import Toast from "./component/toast";
import Success from "./component/success";
import Header from "./component/Header";
import Footer from "./component/footer";
import Proposel from "./component/proposel";
import AnyMtlxPopOver from "./component/anymtlxPopover";

const isValidEmail = (email: any) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const validateData = ({
  name,
  email,
  walletaddress,
  twitter,
  telegram,
  description,
}: any) => {
  const errorObj: any = {};

  if (!name || !name.trim()) errorObj.name = "Please provide the name";
  if (!twitter || !twitter.trim())
    errorObj.twitter = "Please provide your twitter username";
  if (!telegram || !telegram.trim())
    errorObj.telegram = "Please provide your telegram username";
  if (!description || !description.trim())
    errorObj.description = "Please provide the description";

  if (!email || !email.trim()) {
    errorObj.email = "Please provide the email";
  } else if (!isValidEmail(email)) {
    errorObj.email = "Please provide a valid email address..";
  }

  if (!walletaddress || !walletaddress.trim()) {
    errorObj.walletaddress = "Please provide your wallet address";
  } else if (walletaddress.length < 4) {
    errorObj.walletaddress = "Wallet address length should be atleast 4";
  } else if (!/^fetch/i.test(walletaddress)) {
    errorObj.walletaddress = "Wallet address is not valid";
  }

  return errorObj;
};

const EarlyAccess = ({ initialWalletState, connectKeplr }: any) => {
  const [trackError, setTrackError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [toast, setToast] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [hoveredItem, setHoveredItem] = useState<string>("");
  const [load, setLoad] = useState(false);
  const [disableWalletAddress, setDisableWalletAddress] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // const [x, setx] = useState<any>();

  // const getData = async () => {
  //   try {
  //     const res = await fetch("https://sheetdb.io/api/v1/bgja2rgrhyex1");
  //     const data = await res.json();
  //     console.log(data);
  //     setx(Object.keys(data).map((key) => data[key]));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getData();
  // }, []);
  // console.log(x);

  const [formData, setFormData] = useState<any>({
    name: "",
    email: "",
    walletaddress: "",
    twitter: "",
    telegram: "",
    description: "",
  });
  const [formErrors, setFormErrors] = useState<any>({});

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleMenuItemHover = (hoverData: string) => {
    setHoveredItem(hoverData);
  };

  const handleConnect = async () => {
    setLoad(true);
    await connectKeplr(true, "keplr");
    if (initialWalletState.wallet.connected) {
      setTimeout(() => {
        setLoad(false);
      }, 500);
    }
  };
  useEffect(() => {
    if (initialWalletState?.wallet?.address) {
      setTimeout(() => {
        setFormData({
          ...formData,
          walletaddress: initialWalletState.wallet.address,
        });
        setLoad(false);
        setTimeout(() => {
          setDisableWalletAddress(true);
        }, 5000);
      }, 500);
    }
  }, [initialWalletState]);

  const handleMenuItemLeave = () => {
    setHoveredItem("");
  };

  const onKeyChange = (e: any) => {
    if (trackError) {
      setFormErrors(
        validateData({ ...formData, [e.target.name]: e.target.value })
      );
    }
  };

  const onCloseBar = () => {
    setToast(false);
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setTrackError(true);
    const errorObj = validateData(formData);
    setFormErrors(errorObj);
    if (Object.keys(errorObj).length === 0) {
      try {
        setWaiting(true);
        const res = await fetch("https://sheetdb.io/api/v1/bgja2rgrhyex1", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer 1zqrvxtes5t7491kdh19gocul6e5tonn4703l0uw",
          },
          body: JSON.stringify(formData),
        });
        setWaiting(false);
        if (res.ok) {
          setFormSubmitted(true);
          setWaiting(false);
          setToast(true);
          setTimeout(() => {
            setToast(false);
            onCloseBar();
          }, 2000);
        }
      } catch (error) {
        setWaiting(false);
        console.log(error);
      }
    }
  };

  const inputClasses = (field: string) =>
    `block w-full sm:text-sm px-3 py-2 bg-white border border-slate-300 shadow-sm rounded-md
    placeholder-slate-400
    focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1
    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none pl-10
    ${formErrors[field] ? "border-pink-500 text-pink-600 focus:border-pink-500 focus:ring-pink-500" : ""}`;
  const fieldErrorClasses = (field: string) =>
    `mt-2 ${formErrors[field] ? "visible" : "invisible"} text-pink-600 text-sm`;

  if (formSubmitted) {
    return <Success />;
  }

  return (
    <div className="h-screen">
      {/* <Proposel /> */}
      {open && <AnyMtlxPopOver open={open} handleClose={handleClose} />}

      <Header setOpen={setOpen} />
      {toast && <Toast onCloseHandler={onCloseBar} />}
      {waiting && (
        <div className="absolute w-full h-full backdrop-blur-[2px] z-[200000] ">
          <img
            src="images/spinner.svg"
            alt="waiting.svg"
            className="absolute h-[20%] w-[20%] left-[45rem] top-[20rem]"
          />
        </div>
      )}
      <div className="flex space-x-6 justify-center w-full">
        <p className="text-center mt-[7rem] text-indigo-600 text-5xl tracking-wider leading-[4rem] font-bold">
          Get Private Release Access
        </p>
      </div>
      <div className="max-w-lg mx-auto p-6 mt-6 px-6 shadow-lg rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="after:content-['*'] after:ml-0.5 after:text-red-500 block mb-1 text-sm font-medium text-slate-700"
            >
              Name
            </label>
            <div className="input-container">
              <img
                src="/images/name.svg"
                className="input_img "
                alt="name.svg"
              />

              <input
                disabled={formSubmitted}
                type="text"
                name="name"
                id="name"
                className={inputClasses("name")}
                placeholder="Jon Doe"
                onChange={handleChange}
                onKeyUp={onKeyChange}
              />
            </div>

            <p className={fieldErrorClasses("name")}>{formErrors.name}</p>
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="after:content-['*'] after:ml-0.5 after:text-red-500 block mb-1 text-sm font-medium text-slate-700"
            >
              Email
            </label>
            <div className="input-container">
              <img
                src="/images/email.svg"
                className="absolute h-[25px] w-[25px] bottom-[0.3rem] left-[0.3rem]"
                alt="email.svg"
              />

              <input
                disabled={formSubmitted}
                type="email"
                name="email"
                id="email"
                className={inputClasses("email")}
                placeholder="me@example.com"
                onChange={handleChange}
                onKeyUp={onKeyChange}
              />
            </div>
            <p className={fieldErrorClasses("email")}>{formErrors.email}</p>
          </div>

          <div className="mb-4">
            <label
              htmlFor="ExistingDEX"
              onMouseLeave={handleMenuItemLeave}
              className="relative flex mb-1 text-sm font-medium text-slate-700"
            >
              Any Existing DEX / CEX you use
              <img
                src="/images/information.svg"
                alt="information.svg"
                className="ml-[2px] cursor-pointer"
                onMouseEnter={() => handleMenuItemHover("existingDex")}
                height={10}
                width={20}
              />
              {hoveredItem === "existingDex" && (
                <p className="absolute mt-[21px] w-[200px] z-[99999] mr-[4rem] p-[7px] right-0 rounded-md bg-indigo-500 text-white">
                  We will use this information to enhance user experience of
                  Mettalex
                </p>
              )}
            </label>
            <div className="input-container">
              <img
                src="/images/application.svg"
                className="absolute h-[25px] w-[25px] bottom-[0.3rem] left-[0.3rem]"
                alt="application.svg"
              />
              <input
                disabled={formSubmitted}
                type="ExistingDEX"
                name="ExistingDEX"
                id="ExistingDEX"
                className={inputClasses("ExistingDEX")}
                onChange={handleChange}
                placeholder="Uniswap, Binance"
              />
            </div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="walletaddress"
              onMouseLeave={handleMenuItemLeave}
              className="relative after:content-['*'] after:ml-0.5 after:text-red-500 flex mb-1 text-sm font-medium text-slate-700"
            >
              Fetch Wallet Address
              <img
                src="/images/information.svg"
                alt="information.svg"
                onMouseEnter={() => handleMenuItemHover("walletAddress")}
                className="ml-[2px] cursor-pointer"
                height={10}
                width={20}
              />
              {hoveredItem === "walletAddress" && (
                <p className="absolute mt-[21px] w-[200px] z-[99999] mr-[8rem] p-[7px] right-0 rounded-md bg-indigo-500 text-white">
                  Mettalex is available right now only on Fetch Network, but
                  would soon expand to support other networks{" "}
                </p>
              )}
            </label>
            <div className="flex justify-between">
              <div className="input-container w-[70%]">
                <img
                  src="/images/wallet.svg"
                  className="input_img "
                  alt="wallet.svg"
                />
                <input
                  disabled={load}
                  type="walletaddress"
                  name="walletaddress"
                  id="walletaddress"
                  className={inputClasses("walletaddress")}
                  value={formData.walletaddress}
                  onChange={(e) =>
                    setFormData({ ...formData, walletaddress: e.target.value })
                  }
                  placeholder="Connect wallet or paste your address"
                  onKeyUp={onKeyChange}
                />
              </div>
              {load ? (
                <img
                  src="images/spinner.svg"
                  alt="loading.svg"
                  className="flex justify-start mr-16"
                />
              ) : (
                <button
                  onClick={handleConnect}
                  className="bg-indigo-800 hover:bg-indigo-600 text-sm leading-5 rounded-md font-semibold text-white w-[120px]"
                >
                  Connect wallet
                </button>
              )}
            </div>
            <p className={fieldErrorClasses("walletaddress")}>
              {formErrors.walletaddress}
            </p>
          </div>

          <div className="mb-4">
            <label
              onMouseLeave={handleMenuItemLeave}
              htmlFor="twitter"
              className="relative after:content-['*'] after:ml-0.5 after:text-red-500  flex mb-1 text-sm font-medium text-slate-700"
            >
              Twitter Username
              <img
                src="/images/information.svg"
                alt="information.svg"
                onMouseEnter={() => handleMenuItemHover("twitter")}
                className="ml-[2px] cursor-pointer"
                height={10}
                width={20}
              />
              {hoveredItem === "twitter" && (
                <p className="absolute mt-[21px] w-[200px] z-[99999] mr-[9rem] p-[7px] right-0 rounded-md bg-indigo-500 text-white">
                  We will use this to shortlist users for private access
                </p>
              )}
            </label>
            <div className="input-container">
              <img
                src="/images/twitter.svg"
                className="input_img"
                alt="twitter.svg"
              />
              <input
                disabled={formSubmitted}
                type="text"
                name="twitter"
                id="twitter"
                className={inputClasses("twitter")}
                placeholder="Twitter Username"
                onChange={handleChange}
                onKeyUp={onKeyChange}
              />
            </div>
            <p className={fieldErrorClasses("twitter")}>{formErrors.twitter}</p>
          </div>

          <div className="mb-4">
            <label
              htmlFor="telegram"
              className="relative after:content-['*'] after:ml-0.5 after:text-red-500  flex mb-1 text-sm font-medium text-slate-700"
              onMouseLeave={handleMenuItemLeave}
            >
              Telegram Username
              <img
                src="/images/information.svg"
                onMouseEnter={() => handleMenuItemHover("telegram")}
                className="ml-[2px] cursor-pointer"
                alt="information.svg"
                height={10}
                width={20}
              />
              {hoveredItem === "telegram" && (
                <p className="absolute mt-[21px] w-[200px] z-[99999] mr-[8rem] p-[7px] right-0 rounded-md bg-indigo-500 text-white">
                  We will use this to shortlist users for private access
                </p>
              )}
            </label>
            <div className="input-container">
              <img
                src="/images/tel1.svg"
                className="absolute h-[30px] w-[30px] bottom-1 left-[0.3rem]"
                alt="telegram.svg"
              />
              <input
                disabled={formSubmitted}
                type="text"
                name="telegram"
                id="telegram"
                className={inputClasses("telegram")}
                placeholder="Telegram Username"
                onKeyUp={onKeyChange}
                onChange={handleChange}
              />
            </div>
            <p className={fieldErrorClasses("telegram")}>
              {formErrors.telegram}
            </p>
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="after:content-['*'] after:ml-1 after:text-red-500  block mb-1 text-sm font-medium text-slate-700"
            >
              Why do you want to be a part of private release ?
            </label>
            <div className="input-container">
              <img
                src="/images/description.svg"
                className="absolute h-[25px] w-[25px] bottom-7 left-[0.3rem]"
                alt="description.svg"
              />
              <textarea
                disabled={formSubmitted}
                name="description"
                rows={3}
                maxLength={200}
                id="description"
                className={inputClasses("description")}
                placeholder="Please briefly describe your interest in joining the private access program, including how you plan to utilize the exclusive features and contribute to the program's development."
                onChange={handleChange}
                onKeyUp={onKeyChange}
              />
            </div>
            <p className={fieldErrorClasses("description")}>
              {formErrors.description}
            </p>
          </div>

          <div className="text-right">
            <button
              disabled={formSubmitted}
              onClick={handleSubmit}
              className="tracking-wide bg-indigo-800 hover:bg-indigo-600   px-5 py-2.5 text-sm leading-5 rounded-md font-semibold text-white w-full disabled:bg-slate-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {formSubmitted ? "Joined" : "Join Now"}
            </button>
          </div>
        </form>
      </div>
      <div className="relative mt-[5rem]">
        <img
          className="w-[100%] h-[45rem] bottom-0 absolute z-[-9999] md:flex hidden"
          src="images/foot.png"
          alt="headerimage"
        />
        <Footer />
      </div>
    </div>
  );
};

export default EarlyAccess;
