import React from "react";

export interface Title {
  title: string;
}

export interface ProductPageProps {
  heading?: string;
  titles?: Title[];
  image?: string;
  button?: string;
  reverse?: boolean;
  alttext?: string;
}

const Product = ({
  heading,
  titles,
  image,
  button,
  reverse,
  alttext
}: ProductPageProps) => {
  return (
    <div className="w-screen xl:w-[86rem] flex flex-row mx-auto mb-[3.5rem]">
      <div
        className={`flex w-full py-7 justify-around items-center transition ease-in-out duration-500 lg:px-10 ${reverse ? "flex-col lg:flex-row-reverse items-center" : "flex-col lg:flex-row items-center"}`}
      >
        <div className="flex w-[80%] px-5 lg:px-0 lg:w-[45%] xl:w-[50%] justify-center">
          <img
            src={image}
            alt={alttext}
            height={350}
            width={380}
            className=""
          />
        </div>
        <div
          className={`flex flex-col lg:mt-[1rem] w-[90%] lg:w-[90%] xl:w-[50%]  ${reverse ? "lg:mr-[12rem] xl:mr-[18rem]" : "lg:ml-[6rem] xl:ml-[10rem]"}`}
        >
          <h2 className="text-[2rem] mt-10 lg:mt-0  lg:text-start lg:text-[3.2rem] font-bold text-black tracking-wide leading-[3rem] lg:leading-[4rem] mb-2">
            {heading}
          </h2>

          {titles?.map((titles, index) => {
            return (
              <p
                key={index}
                className={`text-gray-700 flex-wrap  lg:text-lg tracking-wide leading-7 lg:leading-8  mt-4 ${reverse ? "lg:w-[120%]" : "w-[100%]"}`}
              >
                {titles.title}
              </p>
            );
          })}

          {/* <button
            type="button"
            className="py-[0.65rem] px-2 mt-6 max-w-[40%] text-center items-center text-base font-semibold rounded-lg border border-transparent bg-black text-white hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 transition-all ease-in-out duration-500 tracking-widest"
          >
            {button}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Product;
