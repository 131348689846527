import React from "react";
import ReadMore from "../readmore";
import BlogCard from "../blogcard";

interface SubBlogData {
  text: string;
  subText: string;
  imagesrc: string;
  redirectsrc: string;
  readmoretitle: string;
  small: boolean;
}

interface BlogData {
  text: string;
  subText: string;
  imagesrc: string;
  redirectsrc: string;
  readmoretitle: string;
}

interface Props {
  blogs: BlogData[];
  subBlogs: SubBlogData[];
}

const ExplorePage = ({ blogs, subBlogs }: Props) => {
  return (
    <div className="w-[98%] 2xl:w-[86rem] flex flex-col mx-auto my-[3.5rem]">
      <p className="ml-[1.3rem] lg:m-2 lg:p-2 font-[600] text-[2rem] lg:ml-10 lg:text-[3.2rem] leading-[5.5rem] tracking-wider ">
        Explore Blogs
      </p>
      <div className="flex-col lg:flex lg:flex-row w-full justify-between items-start transition ease-in-out duration-500 ">
        <div className="relative py-2  px-4 lg:px-11  ">
          <div className="flex flex-col lg:space-y-0 space-y-4 lg:flex-row lg:space-x-20 py-2 lg:h-full">
            {blogs.map((blogsItem, index) => {
              return (
                <div key={index} className="">
                  <BlogCard
                    text={blogsItem.text}
                    subText={blogsItem.subText}
                    imagesrc={blogsItem.imagesrc}
                    redirectsrc={blogsItem.redirectsrc}
                    readmoretitle={blogsItem.readmoretitle}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex w-[100%] justify-around items-start transition ease-in-out duration-500 px-4">
        <div className="relative py-3 sm:mx-auto lg:px-6 w-full">
          <div className="flex flex-col justify-center py-2 w-full shadow-sm border border-gray-100 sm:rounded-xl">
            <div className="relative grid grid-cols-1 gap-y-4 md:gap-y-0 md:grid-cols-3 gap-x-6  sm:p-4 bg-white w-full">
              {subBlogs.map((subItems, index) => {
                return (
                  <BlogCard
                    key={index}
                    text={subItems.text}
                    subText={subItems.subText}
                    imagesrc={subItems.imagesrc}
                    redirectsrc={subItems.redirectsrc}
                    readmoretitle={subItems.readmoretitle}
                    small={subItems.small}
                  />
                );
              })}
            </div>
            <div className="ml-6 mr-[0.4rem] self-end">
              <ReadMore
                redirect="https://docs.mettalex.com/blog/mettalex_quarterly_Update_Q3"
                title="Explore More Blogs"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExplorePage;
