import React from "react";

interface SocialCardsTypes {
  comment: string;
  name: string;
  position: string;
  imgsrc: string;
  twitter?: string;
  linkedin?: string;
  github?: string;
  telegram?: string;
}

const TeamCards = ({
  comment,
  name,
  position,
  imgsrc,
  telegram,
  github,
  linkedin,
  twitter,
}: SocialCardsTypes) => {
  return (
    <div className="rounded-lg flex flex-col md:h-[35rem]">
      <div className="xl:p-5 w-full flex justify-center md:justify-start">
        <img
          className="rounded-bl-[0] shadow-lg md:rounded-lg sm:rounded-br-[0] object-cover h-[17rem]  w-[17rem]"
          src={imgsrc}
          alt="Team.img"
        />
      </div>
      <div className="px-16 md:px-5 py-5 flex flex-col justify-center md:justify-start">
        <p className="text-sm xl:text-xl font-bold tracking-tight text-gray-900  md:text-start">
          <p>{name}</p>
        </p>
        <span className="text-gray-500  text-sm xl:text-lg flex justify-start">
          {position}
        </span>
        <p className="mt-3 mb-4 font-light text-gray-500 text-xs tracking-wider leading-2 text-start">
          {comment}
        </p>
        <ul className="flex text-center	item-center space-x-4 sm:mt-auto justify-start">
          {telegram && (
            <li>
              <a
                href={telegram}
                className="text-gray-600 hover:text-gray-500"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  viewBox="0 0 48 48"
                  className="w-6 h-6"
                  id="Layer_2"
                  data-name="Layer 2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />

                  <g id="SVGRepo_iconCarrier">
                    <path d="M40.83,8.48c1.14,0,2,1,1.54,2.86l-5.58,26.3c-.39,1.87-1.52,2.32-3.08,1.45L20.4,29.26a.4.4,0,0,1,0-.65L35.77,14.73c.7-.62-.15-.92-1.07-.36L15.41,26.54a.46.46,0,0,1-.4.05L6.82,24C5,23.47,5,22.22,7.23,21.33L40,8.69a2.16,2.16,0,0,1,.83-.21Z" />
                  </g>
                </svg>
              </a>
            </li>
          )}
          {github && (
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href={github}
                className="text-gray-600 hover:text-gray-500"
              >
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
          )}
          {twitter && (
            <li className="">
              <a
                href={twitter}
                target="_blank"
                className="text-gray-600 hover:text-gray-500"
                rel="noreferrer"
              >
                <svg
                  className="w-[15px] h-4 mt-1"
                  viewBox="0 0 1200 1227"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </li>
          )}
          {linkedin && (
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href={linkedin}
                className="text-gray-600 hover:text-gray-500"
              >
                <svg
                  fill="currentColor"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0 0 512 512"
                >
                  <g id="7935ec95c421cee6d86eb22ecd125aef">
                    <path
                      d="M116.504,500.219V170.654H6.975v329.564H116.504
		L116.504,500.219z M61.751,125.674c38.183,0,61.968-25.328,61.968-56.953c-0.722-32.328-23.785-56.941-61.252-56.941
		C24.994,11.781,0.5,36.394,0.5,68.722c0,31.625,23.772,56.953,60.53,56.953H61.751L61.751,125.674z M177.124,500.219
		c0,0,1.437-298.643,0-329.564H286.67v47.794h-0.727c14.404-22.49,40.354-55.533,99.44-55.533
		c72.085,0,126.116,47.103,126.116,148.333v188.971H401.971V323.912c0-44.301-15.848-74.531-55.497-74.531
		c-30.254,0-48.284,20.38-56.202,40.08c-2.897,7.012-3.602,16.861-3.602,26.711v184.047H177.124L177.124,500.219z"
                    ></path>
                  </g>
                </svg>
              </a>
            </li>
          )}
          {!linkedin && !twitter && !github && !telegram && (
            <div className="mt-4" />
          )}
        </ul>
      </div>
    </div>
  );
};

export default TeamCards;
