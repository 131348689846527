import React from "react";

interface DropdownItem {
  heading: string;
  title: string;
  linkref?: string;
}

interface DropdownProps {
  dropDownItems: DropdownItem[];
  visible: number | null;
  index: number;
}

const Dropdown = ({ dropDownItems, visible, index }: DropdownProps) => {
  return (
    <div
      className={`shadow-xl opacity-90 backdrop-filter backdrop-blur-lg fixed transition-opacity duration-500 min-w-[15rem] mr-4 bg-white rounded-lg p-2   after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full  ${visible === index ? "" : "hidden"}`}
    >
      {dropDownItems.map((item: DropdownItem) => {
        return (
          <a
            className="flex flex-col items-start row-span-6 gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 "
            href={item.linkref}
            key={index + Math.random()}
            rel="noreferrer"
          >
            <p className="font-semibold">{item.heading}</p>
            <p>{item.title}</p>
          </a>
        );
      })}
    </div>
  );
};

export default Dropdown;
