import { CHAIN_ID, KEPLR_CHAIN_CONFIG } from "./constants";

export async function initKeplr(shouldSuggestChain: any, walletType: any) {
  const [keplr] = await detectKeplr(walletType);

  if (!keplr) {
    return `Install ${walletType === "leap" ? "Leap" : "Fetch"} Wallet`;
  }

  try {
    await keplr.enable(CHAIN_ID);
  } catch (error) {
    await keplr.experimentalSuggestChain(KEPLR_CHAIN_CONFIG);
    if (error) {
      return;
    } else if (!shouldSuggestChain) {
      console.log(error);
    }
  }
  return keplr;
}

export async function detectKeplr(walletType: any) {
  if (window[walletType]) {
    const keplr: any = window[walletType];

    let version = "unknown";
    if (keplr.version.startsWith("0.8") || keplr.version.startsWith("0.9")) {
      version = "keplr";
    } else if (keplr.version.startsWith("0.2")) {
      version = "fetch";
    } else if (keplr.version.startsWith("0.1")) {
      version = "fetch-legacy";
    }

    return [keplr, version];
  }

  return [undefined, "unknown"];
}
