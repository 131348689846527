/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useRef } from "react";
import Dropdown from "../dropdown";
import { Link } from "react-router-dom";

interface DropdownObj {
  heading: string;
  title: string;
  linkref: string;
}

interface clickItem {
  title: string;
  url?: string;
  dropdown?: DropdownObj[];
}

export default function Header({ setOpen }: any) {
  const [animateHeader, setAnimateHeader] = useState(false);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [clickedItem, setClickedItem] = useState(false);

  const clickedItemHandler = (item: clickItem) => {
    item.dropdown && setClickedItem(!clickedItem);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  useEffect(() => {
    const listener = () => {
      if (window.scrollY > 250) {
        setAnimateHeader(true);
      } else setAnimateHeader(false);
    };
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);
  const menuItems = [
    {
      title: "Docs",
      url: "https://docs.mettalex.com/docs/MettalexOverview",
      // dropdown: [
      //   { heading: "start here", title: "What is Mettalex" },
      //   { heading: "start here", title: "What is Mettalex" },
      //   { heading: "start here", title: "What is Mettalex" },
      // ],
    },
    {
      title: "Blog",
      url: "https://docs.mettalex.com/blog/mettalex_quarterly_Update_Q3",
      // dropdown: [
      //   { heading: "start here", title: "What is metallex2" },
      //   { heading: "start here", title: "What is metallex2" },
      //   { heading: "start here", title: "What is metallex2" },
      // ],
    },
    {
      title: "Roadmap",
      url: "https://docs.mettalex.com/resources/roadmap",
      // dropdown: [
      //   { heading: "start here", title: "What is metallex2" },
      //   { heading: "start here", title: "What is metallex2" },
      //   { heading: "start here", title: "What is metallex2" },
      // ],
    },
    {
      title: "Old Mettalex",
      url: "https://old.mettalex.com/",
    },
    {
      title: "FAQs",
      url: "/faq",
    },
    // {
    //   title: "Buy MTLX",
    //   url: "https://app.uniswap.org/tokens/ethereum/0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14",
    // },
    // {
    //   title: "Miscellaneous",
    //   dropdown: [
    //     { heading: "About us", title: "About Mettalex", linkref: "/aboutus" },
    //     { heading: "Career", title: "Career at mettalex", linkref: "/careers" },
    //     { heading: "Team", title: "Meet our team", linkref: "/team" },
    //   ],
    // },
  ];

  const handleMenuItemHover = (index: number) => {
    setHoveredItem(index);
  };

  const handleMenuItemLeave = () => {
    setHoveredItem(null);
  };
  return (
    <header
      className={`w-screen fixed z-10 trasition ease-in-out duration-300 ${
        animateHeader
          ? "shadow-lg backdrop-filter backdrop-blur-2xl"
          : "shadow-lg backdrop-filter backdrop-blur-2xl bg-gradient-to-r from-[#E3DBFD] from-[20%] via-[#9379E7] via-[60%] to-[#FFFFFF]"
      }`}
    >
      <div
        className={`${showMobileMenu && "h-[100vh] z-[999999] trasition ease-in-out duration-500 bg-white shadow-md backdrop-filter backdrop-blur-lg"}`}
      >
        <div
          className={`fixed left-5 ${animateHeader ? "top-[0.4rem] transition ease-in-out duration-500" : "top-[0.75rem]"}`}
        >
          <div className="lg:hidden ">
            {showMobileMenu ? (
              <img
                src="images/close.svg"
                alt="hamburgerClose"
                onClick={toggleMobileMenu}
                className="text-xl text-black p-2 focus:outline-none w-[3.4rem]"
              />
            ) : (
              <img
                src="images/hamburger.svg"
                alt="hamburgerImage"
                onClick={toggleMobileMenu}
                className="text-xl text-black p-2 focus:outline-none w-[3.4rem]"
              />
            )}
          </div>
        </div>
        {showMobileMenu && (
          <ul className="absolute top-[5rem] left-[1rem] ">
            {menuItems?.map((item: clickItem, index) => (
              <div className="flex flex-col" key={index}>
                <li key={index} onClick={() => clickedItemHandler(item)}>
                  <a
                    target={item.title === "About us" || "FAQ" ? "" : "_Blank"}
                    href={item.url}
                    className="flex py-2 text-gray-700 px-4 hover:text-indigo-500 text-2xl font-[550] tracking-wider"
                    rel="noreferrer"
                  >
                    {item.title}
                    {item.title === "Buy MTLX" && (
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        transform="rotate(-45)"
                        height={27}
                        width={27}
                        className={`ml-1 mb-1 ${
                          hoveredItem &&
                          "rotate-[40] transition-transform duration-700 ease-out"
                        } ${hoveredItem === null && "transition-transform duration-700 ease-out"}`}
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M6 12H18M18 12L13 7M18 12L13 17"
                            stroke="#000000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>{" "}
                        </g>
                      </svg>
                    )}
                    {item.dropdown && (
                      <svg
                        className={`w-4 h-4 ml-2  mt-2 flex items-center transition-transform duration-700 ease-out ${
                          animateHeader
                            ? "text-base ease-in-out duration-500"
                            : "text-xl ease-in-out duration-500"
                        } ${
                          clickedItem
                            ? "rotate-180 transition-transform duration-700 ease-out"
                            : ""
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="m6 9 6 6 6-6" />
                      </svg>
                    )}
                  </a>
                </li>
                {item.dropdown && (
                  <div
                    className={`transition-all ease-in-out duration-700 ${
                      clickedItem ? "opacity-1 visible" : "opacity-0 invisible"
                    }`}
                  >
                    <Dropdown
                      dropDownItems={item.dropdown}
                      visible={1}
                      index={1}
                    />
                  </div>
                )}
              </div>
            ))}
            <div className="flex flex-col">
              <li>
                <a
                  rel="noreferrer"
                  className="flex py-2 text-gray-700 px-4 hover:text-indigo-500 text-2xl font-[550] tracking-wider"
                >
                  Buy MTLX
                </a>
              </li>
            </div>
          </ul>
        )}
      </div>
      <div
        className={`flex lg:px-[3rem] 2xl:px-[16.3rem] overflow-x-hidden  ${
          animateHeader ? "py-1" : "py-1"
        } ${showMobileMenu && "fixed top-0 right-2"} mx-auto items-start justify-center lg:justify-between transition ease-in-out duration-500`}
      >
        <div className="flex flex-row items-center justify-center">
          <Link
            to="/"
            onClick={handleScrollToTop}
            className={`flex flex-col  justify-center font-[450] tracking-widest leading-[22px] text-black  ${animateHeader ? "text-blue-800 text-lg lg:text-xl ease-in-out duration-500" : "text-blue-950 text-lg lg:text-3xl ease-in-out duration-500"}`}
          >
            {/* Mettalex
            <br />
            <span
              className={`font-bold tracking-widest leading-[12px] text-black pr-8 ${animateHeader ? "text-[0.5rem] ease-in-out duration-500 text-blue-800" : "text-[0.6rem] ease-in-out duration-500 text-blue-950"}`}
            >
              POWERED BY FETCH.AI
            </span> */}
            <img
              src="images/Mettalex_Logo_Blue.svg"
              alt="Mettalex Logo"
              height={50}
              width={animateHeader ? 160 : 220}
              className="ease-in-out duration-500"
            />
          </Link>
        </div>

        <nav>
          <ul className="lg:flex items-start justify-start space-x-4 hidden">
            {menuItems?.map((item, index) => (
              <div className="flex flex-col" key={index}>
                <li
                  key={item?.title}
                  onMouseEnter={() =>
                    item?.title === "Buy MTLX" && handleMenuItemHover(index)
                  }
                  onMouseLeave={() =>
                    item?.title === "Buy MTLX" && handleMenuItemLeave()
                  }
                >
                  <a
                    target={item.title === "About us" || "FAQ" ? "" : "_Blank"}
                    href={item.url}
                    className={`flex tracking-wider items-center text-gray-700 flex-row px-2 lg:px-2 py-6 text-md  leading-[22px] md:px-3  hover:text-indigo-500 ${animateHeader ? "text-base ease-in-out duration-500" : "text-xl ease-in-out duration-500"}`}
                    rel="noreferrer"
                  >
                    {item?.title}
                    {item.title === "Buy MTLX" && (
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        transform="rotate(-45)"
                        height={22}
                        width={22}
                        className={`ml-1 ${
                          hoveredItem &&
                          "rotate-[40] transition-transform duration-700 ease-out"
                        } ${hoveredItem === null && "transition-transform duration-700 ease-out"}`}
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M6 12H18M18 12L13 7M18 12L13 17"
                            stroke={hoveredItem ? "#4f46e5" : "#000000"}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>{" "}
                        </g>
                      </svg>
                    )}
                    {/* {item.dropdown && (
                      <svg
                        className={`w-4 h-4 ml-2 flex items-center transition-transform duration-700 ease-out ${
                          animateHeader
                            ? "text-base ease-in-out duration-500"
                            : "text-xl ease-in-out duration-500"
                        } ${
                          hoveredItem === index
                            ? "rotate-180 transition-transform duration-700 ease-out"
                            : ""
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="m6 9 6 6 6-6" />
                      </svg>
                    )} */}
                  </a>
                </li>
                {/* {item.dropdown && (
                  <div
                    className={`transition-all ease-in-out duration-700 ${
                      hoveredItem === index
                        ? "opacity-1 visible"
                        : "opacity-0 invisible"
                    }`}
                  >
                    <Dropdown
                      dropDownItems={item.dropdown}
                      visible={hoveredItem}
                      index={index}
                    />
                  </div>
                )} */}
              </div>
            ))}
            <div className="flex flex-col">
              <li>
                <span
                  onClick={() => setOpen(true)}
                  className="cursor-pointer flex tracking-wider items-center text-gray-700 flex-row px-2 lg:px-2 py-6 text-md  leading-[22px] md:px-3  hover:text-indigo-500 text-xl ease-in-out duration-500"
                >
                  Buy MTLX
                </span>
              </li>
            </div>
          </ul>
        </nav>
      </div>
    </header>
  );
}
