import React from "react";
// import { redirect } from "react-router-dom";
// import { Link } from "react-router-dom";

interface CommunityItem {
  title: string;
  description: string;
  icon: string;
  redirectlink: string;
  alttext: string;
}

interface CommunityProps {
  communityItems: CommunityItem[];
}

const Community = ({ communityItems }: CommunityProps) => {
  return (
    <div className="w-screen 2xl:w-[86rem] flex flex-col mx-auto pb-[3.5rem] my-[6rem]">
      <div className="flex  flex-col px-[1.3rem] w-full py-7 justify-start transition ease-in-out duration-500 lg:px-11">
        <p className="font-[600] text-[2rem] lg:text-[3.2rem] leading-[3rem] tracking-wider ">
          Get Involved with Mettalex
        </p>
        {/* <p className="text-gray-600 flex-wrap lg:text-[1rem] tracking-wide leading-7 lg:leading-8 mt-4 w-[90%] lg:w-[100%]">
          Join our dynamic community and let's innovate together!
        </p> */}
      </div>
      <div className="flex flex-col mt-4 text-gray-700 bg-white shadow-sm border rounded-xl lg:px-4 lg:mx-11 mx-[2rem]">
        <div className="py-6 px-2 grid md:grid-cols-4 gap-y-12 justify-center items-center">
          {communityItems.map((item, index) => (
            <div
              className="flex flex-row w-full space-x-4 lg:space-x-3 xl:w-[90%]"
              key={index}
            >
              {/* <span className="flex items-center  justify-center cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-2 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
                {item.icon}
              </span> */}
              <a
                className="flex items-center justify-center cursor-pointer w-[20%]  "
                href={item.redirectlink}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[3.5rem] border border-transparent rounded-2xl"
                  src={item.icon}
                  alt={item.alttext}
                />
              </a>
              <div className="flex flex-col justify-center w-[80%]">
                <a
                  className="block font-sans text-sm lg:text-xl antialiased font-bold leading-snug tracking-normal hover:hover:text-indigo-500 hover:underline"
                  href={item.redirectlink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.title}
                </a>
                <p className="block font-sans text-xs antialiased font-light leading-relaxed tracking-wide text-gray-500 ">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="p-6 pt-0 self-end">
          <Link
            to="/community"
            className="py-3 px-5 inline-flex items-center gap-x-2 text-base font-semibold rounded-lg border border-transparent bg-indigo-800 text-white hover:bg-indigo-400 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 transition-all ease-in-out duration-500 tracking-widest"
          >
            Find More
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Community;
