import React, { useState } from "react";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";

const LandingSection = () => {
  const [modal, setModal] = useState(false);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  const handleMenuItemHover = (index: number) => {
    setHoveredItem(index);
  };

  const handleMenuItemLeave = () => {
    setHoveredItem(null);
  };

  return (
    <div className="flex flex-col items-center lg:mb-[10rem] xl:mb-[2rem] mb-2">
      {modal ? (
        <section
          className="fixed top-0 left-0 w-full h-full bg-slate-700/40"
          style={{ zIndex: 999 }}
          onClick={() => {
            setModal(!modal);
          }}
        >
          <div className="m-auto">
            <div className="flex h-screen">
              <div className="m-auto">
                <iframe
                  className="w-[400px] h-[300px] md:w-[760px] md:h-[500px] lg:w-[1000px] lg:h-[600px]"
                  loading="lazy"
                  src="https://www.youtube.com/embed/l0ym9gOrGFw"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      ) : undefined}
      <div className="flex flex-col w-full lg:w-[93%] 2xl:w-[60%] justify-center mt-[8rem] lg:mt-[10.3rem]">
        <h1 className="m-2 p-2 text-black font-[1000] text-[2.2rem] lg:text-[4rem] 2xl:text-[5rem] text-center leading-[3rem] tracking-wider lg:leading-[5.5rem] lg:tracking-wide ">
          World’s first P2P
          <Typewriter
            options={{
              strings: [
                '<span style="color: #4F46E5;" >Order book</span>',
                '<span style="color: #4F46E5;" >Agent based</span>',
              ],
              autoStart: true,
              loop: true,
              cursor: '<span style="color: #4F46E5;" >|</span>',
            }}
          />
          DEX
        </h1>
        <p className="px-[2rem] lg:px-[7rem] 2xl:px-[13.5rem] text-sm leading-6 lg:text-xl font-normal text-center mt-2 lg:mt-3 tracking-wide text-gray-800">
          Mettalex offers a next-generation trading experience in DeFi,
          combining Fetch.ai's advanced agent-based technology with a seamless,
          secure platform for efficient chain agnostic trades.
        </p>
        <div className="flex p-2 justify-center mt-[2rem] lg:mt-[3rem] space-x-4">
          <button
            className="py-3 sm:px-7 px-3 inline-flex items-center gap-x-2 text-sm sm:text-base font-semibold rounded-lg border border-transparent bg-indigo-800 text-white hover:bg-indigo-600 disabled:opacity-50 disabled:pointer-events-none  transition-all ease-in-out duration-500 tracking-widest"
            rel="noreferrer"
            onClick={() => setModal(!modal)}
          >
            Watch Demo
          </button>
          <Link
            to="/earlyaccess"
            type="button"
            onMouseEnter={() => handleMenuItemHover(1)}
            onMouseLeave={handleMenuItemLeave}
            className="py-3 sm:px-7 px-3 inline-flex items-center gap-x-2 text-sm sm:text-base font-semibold rounded-lg border border-transparent bg-indigo-800 text-white hover:bg-indigo-600 disabled:opacity-50 disabled:pointer-events-none transition-all ease-in-out duration-500 tracking-widest"
          >
            Request early access
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              transform="rotate(-45)"
              height={27}
              width={27}
              className={`ml-1 mb-1 ${
                hoveredItem &&
                "rotate-[40] transition-transform duration-700 ease-out"
              } ${hoveredItem === null && "transition-transform duration-700 ease-out"}`}
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M6 12H18M18 12L13 7M18 12L13 17"
                  stroke="#ffff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingSection;
