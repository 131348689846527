import { Box, Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

const AnyMtlxPopOver = ({ open, handleClose }: any) => {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  return (
    <Dialog
      sx={{ padding: "10px" }}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f2",
          boxShadow: "none",
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <Typography
        className="text-xxl font-bold text-center text-indigo-800"
        style={{ marginTop: "16px" }}
      >
        Buy MTLX
      </Typography>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box className="flex items-baseline justify-center flex-col p-10">
        <p className="p-1 bg-white rounded-lg shadow-lg hover:bg-gray-100">
          Ethereum:
          <a
            onMouseEnter={() => setHoveredItem(3)}
            onMouseLeave={() => setHoveredItem(null)}
            rel="noreferrer"
            href="https://app.uniswap.org/explore/tokens/ethereum/0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14"
            target="_Blank"
            className="flex items-center hover:text-indigo-500"
          >
            Buy Now{" "}
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              transform="rotate(-45)"
              height={22}
              width={22}
              className={`ml-1 ${
                hoveredItem === 3 &&
                "rotate-[40] transition-transform duration-700 ease-out"
              } ${hoveredItem === null && "transition-transform duration-700 ease-out"}`}
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M6 12H18M18 12L13 7M18 12L13 17"
                  stroke={hoveredItem === 3 ? "#4f46e5" : "#000000"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </a>
          <a
            onMouseEnter={() => setHoveredItem(1)}
            onMouseLeave={() => setHoveredItem(null)}
            rel="noreferrer"
            href="https://etherscan.io/token/0x2e1E15C44Ffe4Df6a0cb7371CD00d5028e571d14"
            target="_Blank"
            className="flex items-center hover:text-indigo-500"
          >
            0x2e1E15C44Ffe4Df6a0cb7371CD00d5028e571d14{" "}
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              transform="rotate(-45)"
              height={22}
              width={22}
              className={`ml-1 ${
                hoveredItem === 1 &&
                "rotate-[40] transition-transform duration-700 ease-out"
              } ${hoveredItem === null && "transition-transform duration-700 ease-out"}`}
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M6 12H18M18 12L13 7M18 12L13 17"
                  stroke={hoveredItem === 1 ? "#4f46e5" : "#000000"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </a>
        </p>
        <p className="p-2 mt-3 bg-white rounded-lg shadow-lg hover:bg-gray-100">
          Binance:
          <a
            onMouseEnter={() => setHoveredItem(4)}
            onMouseLeave={() => setHoveredItem(null)}
            rel="noreferrer"
            href="https://pancakeswap.finance/info/toke/tokens/0x5921DEE8556c4593EeFCFad3CA5e2f618606483b"
            target="_Blank"
            className="flex items-center hover:text-indigo-500"
          >
            Buy Now{" "}
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              transform="rotate(-45)"
              height={22}
              width={22}
              className={`ml-1 ${
                hoveredItem === 4 &&
                "rotate-[40] transition-transform duration-700 ease-out"
              } ${hoveredItem === null && "transition-transform duration-700 ease-out"}`}
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M6 12H18M18 12L13 7M18 12L13 17"
                  stroke={hoveredItem === 4 ? "#4f46e5" : "#000000"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </a>
          <a
            onMouseEnter={() => setHoveredItem(2)}
            onMouseLeave={() => setHoveredItem(null)}
            rel="noreferrer"
            href="https://bscscan.com/token/0x5921dee8556c4593eefcfad3ca5e2f618606483b"
            target="_Blank"
            className="flex items-center hover:text-indigo-500"
          >
            0x5921dee8556c4593eefcfad3ca5e2f618606483b
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              transform="rotate(-45)"
              height={22}
              width={22}
              className={`ml-1 ${
                hoveredItem === 2 &&
                "rotate-[40] transition-transform duration-700 ease-out"
              } ${hoveredItem === null && "transition-transform duration-700 ease-out"}`}
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M6 12H18M18 12L13 7M18 12L13 17"
                  stroke={hoveredItem === 2 ? "#4f46e5" : "#000000"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </a>{" "}
        </p>
        {/* <button className="walletConnectbtn">
          
        </button>
        <button
          className="walletConnectbtn"
          disabled={bt1Name === "FetchKeplr"}
          onClick={handleLeapWalletClick}
          // style={{ textTransform: 'none' }}
        >
          {bt2Name}
        </button> */}
      </Box>
    </Dialog>
  );
};

export default AnyMtlxPopOver;
