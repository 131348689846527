export const products = [
  {
    image: "images/Liquidity.png",
    heading: "No Liquidity Pools",
    titles: [
      {
        title:
          "Mettalex distinguishes itself by eliminating the concept of liquidity pools, unlike existing DEXs. This approach removes the dependency on liquidity providers and allows the system to operate efficiently even with low liquidity.",
      },
    ],
    button: "Learn Mettalex",
    alttext: "No LP",
  },
  {
    image: "images/Vectors.png",
    heading: "Zero Slippage",
    titles: [
      {
        title:
          "Experience seamless transactions with Mettalex's revolutionary zero slippage trading model. Our platform ensures that your trades are executed at the price you expect, every time, without the cost of slippage affecting your trading strategy.",
      },
    ],
    button: "Build Modular",
    reverse: true,
    alttext: "Zero Slippage",
  },
  {
    image: "images/ChainAgnosticAgents.svg",
    heading: "Chain Agnostic",
    titles: [
      {
        title:
          "Trade across multiple blockchain networks seamlessly without worrying about bridging your assets, and enjoy the flexibility to trade across various ecosystems without being bound to a single chain with Mettalex: your trading, your blockchain, your rules.",
      },
    ],
    button: "Build Modular",
    reverse: false,
    alttext: "Chain Agnostic",
  },
  {
    image: "images/Decentralized.png",
    heading: "Complete Decentralization",
    titles: [
      {
        title:
          "Mettalex's decentralized deployment allows the platform to operate across multiple environments - including but not limited to your local machines, cloud providers or even your smart fridge. This ensures an unmatched level of decentralization and adaptability, setting Mettalex apart from conventional platforms.",
      },
    ],
    button: "Build Modular",
    reverse: true,
    alttext: "Complete Decentralization",
  },
];
export const communityItems = [
  {
    title: "X",
    description:
      "Check out the latest buzz from the Mettalex community on Twitter.",
    icon: "images/X.svg",
    redirectlink: "https://twitter.com/mettalex",
    alttext: "twitter",
  },

  {
    title: "Telegram",
    description: "Join the conversation about Mettalex on Telegram.",
    icon: "images/telegram.svg",
    redirectlink: "https://t.me/mettalex_official",
    alttext: "Telegram",
  },

  {
    title: "LinkedIn",
    description:
      "Connect with us on LinkedIn to stay informed on the latest updates from Mettalex.",
    icon: "images/linkedin.svg",
    redirectlink: "https://www.linkedin.com/company/mettalex",
    alttext: "Linkedin",
  },
  {
    title: "YouTube",
    description:
      "Connect with us on Youtube to stay informed on the latest updates from Mettalex.",
    icon: "images/youtube.svg",
    redirectlink: "https://www.youtube.com/@Mettalex_dex",
    alttext: "YouTube",
  },
];
export const blogData = [
  {
    text: "Mettalex Quarterly Update Q3 2024",
    subText: "BLOG | October 9, 2024 · 3 MIN READ",
    imagesrc: "images/Q3Blog.png",
    redirectsrc: "https://docs.mettalex.com/blog/mettalex_quarterly_Update_Q3",
    readmoretitle: "Read Now",
  },
  {
    text: "Mettalex Token 2049 Recap",
    subText: "BLOG | September 26, 2024 · 3 MIN READ",
    imagesrc: "images/Token2049.png",
    redirectsrc: "https://docs.mettalex.com/blog/update_token_2049/",
    readmoretitle: "Read Now",
  },
];

export const subBlogsData = [
  {
    text: "Mettalex August - 2024 Update",
    subText: "BLOG | August 16, 2024 · 7 MIN READ",
    imagesrc: "images/august_update.png",
    redirectsrc: "https://docs.mettalex.com/blog/update_august_24/",
    readmoretitle: "Read Now",
    small: true,
  },
  {
    text: "Mettalex Quarterly Q2 2024 Update",
    subText: "BLOG | july 10, 2024 · 6 MIN READ",
    imagesrc: "images/quarterly.png",
    redirectsrc: "https://docs.mettalex.com/blog/mettalex_quarterly_update",
    readmoretitle: "Read Now",
    small: true,
  },
  {
    text: "Mettalex June - 2024 Update",
    subText: "BLOG | JUNE 12, 2024 · 2 MIN READ",
    imagesrc: "images/june.png",
    redirectsrc: "https://docs.mettalex.com/blog/update_june",
    readmoretitle: "Read Now",
    small: true,
  },
  // {
  //   text: "Mettalex User Guide - Private Beta",
  //   subText: "BLOG | MAY 27, 2024 · 8 MIN READ",
  //   imagesrc: "images/Mail_Background.png",
  //   redirectsrc:
  //     "https://docs.mettalex.com/blog/Mettalex_Private_Beta_User_Guide",
  //   readmoretitle: "Read Now",
  //   small: true,
  // },
  // {
  //   text: "Mettalex May, 2024 Update",
  //   subText: "BLOG | MAY 23, 2024 · 2 MIN READ",
  //   imagesrc: "images/may.png",
  //   redirectsrc: "https://docs.mettalex.com/blog/update_may",
  //   readmoretitle: "Read Now",
  //   small: true,
  // },
  // {
  //   text: "Mettalex April, 2024 Update",
  //   subText: "BLOG | APRIL 23, 2024 · 3 MIN READ",
  //   imagesrc: "images/April.png",
  //   redirectsrc: "https://docs.mettalex.com/blog/update_april",
  //   readmoretitle: "Read Now",
  //   small: true,
  // },
];

export const socialProof = [
  {
    image: "/images/team/Humayun.png",
    name: "Humayun Sheikh",
    position: "Founder and CEO",
    comment:
      "Entrepreneur, AI & blockchain visionary with a rich legacy from co-founding Fetch.ai to pioneering in financial markets. A true innovator shaping the future of DeFi.",
    twitter: "https://twitter.com/HMsheikh4",
    linkedin: "https://www.linkedin.com/in/humayun-sheikh-4530246a/",
  },
  {
    image: "/images/team/Maria.png",
    name: "Maria Minaricova",
    position: "Business Development",
    comment:
      "Spearheading business development at Fetch.ai and Mettalex, she's a master of strategic alliances and collaborations, leveraging her extensive experience to drive innovation and growth.",
    twitter: "https://twitter.com/MariaMinaricova",
    linkedin: "https://www.linkedin.com/in/mariaminaricova/",
  },
  {
    image: "/images/team/Vijay2.png",
    name: "Chirag Maliwal",
    position: "Project Manager",
    comment:
      "Mettalex's dynamic force, he turns complex challenges into innovative solutions, inspiring the team to exceed their limits.",
    github: "https://github.com/cmaliwal",
    twitter: "https://twitter.com/chiragmaliwal3",
    linkedin: "https://www.linkedin.com/in/chirag-maliwal-92796a107/",
  },
  {
    image: "/images/team/mehul.png",
    name: "Mehul Gaidhani",
    position: "Technical Product Lead",
    comment:
      "A tech enthusiast and advocate for decentralization, thrives on innovation and problem-solving at Mettalex. His progressive approach drives Mettalex's evolution in the digital finance landscape.",
    github: "https://github.com/MehulG",
    twitter: "https://twitter.com/0xmmmehulll",
    linkedin: "https://www.linkedin.com/in/mmmehulll/",
  },
  {
    image: "/images/team/monish.jpeg",
    name: "Monish Muralidharan",
    position: "Marketing Manager",
    comment:
      "A dynamic Web3 marketer renowned for leading campaigns that significantly boost user acquisition and engagement is now set to elevate Mettalex to new heights.",
    telegram: "https://t.me/MonishMuralidharan",
    twitter: "https://twitter.com/CryptoMonish",
    linkedin: "https://www.linkedin.com/in/monish016/",
  },

  {
    image: "/images/team/Deepesh.jpg",
    name: "Deepesh Bhavsar",
    position: "Senior Full Stack Developer",
    comment:
      "Deepesh harnesses a wide array of technologies to enhance Mettalex's product offerings, driven by his passion for technology exploration.",
  },

  {
    image: "/images/team/Devendra.png",
    name: "Devendra Chauhan",
    position: "Smart Contract Engineer",
    comment:
      "Devendra thrives on transforming innovative ideas into blockchain realities. His commitment to decentralization and impactful solutions drives Mettalex forward.",
    github: "https://github.com/Devendra116",
  },
  {
    image: "/images/team/manish.jpg",
    name: "Manish Singh",
    position: "Senior Frontend Developer",
    comment:
      "Manish excels in transforming ideas into captivating digital experiences with his innovative design sense and tech expertise.",
    github: "https://github.com/ManishSinghCoder",
  },
  {
    image: "/images/team/Abhay.jpg",
    name: "Abhay Rahul",
    position: "Product Designer",
    comment:
      "Abhay, a computer science engineer turned designer, brings a rich blend of user-centric design and technical knowledge at Mettalex. He focuses on driving product design to new heights.",
  },
];
