import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Link } from "react-router-dom";

const url =
  "https://mettalex.us12.list-manage.com/subscribe/post?u=2829ae939cb92110b4f56979c&amp;id=ad65c4fcfb&amp;f_id=00e15de0f0";

const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, showError] = useState(false);

  const handleInputChange = (e: any) => {
    setEmail(e.target.value);
    if (pattern.test(e.target.value)) {
      showError(false);
    } else {
      showError(true);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="w-screen overflow-x-hidden 2xl:w-[86rem] flex flex-col mx-auto pb-[3.5rem] pt-[40px]">
      <div className="flex flex-col lg:flex-row w-full py-7 items-start transition ease-in-out duration-500 justify-between lg:px-11">
        <div className="flex flex-col lg:w-[40%] px-5 lg:px-0">
          <label className="text-[2.5rem] lg:px-0 lg:text-[2.8rem] font-bold text-indigo-950 tracking-wide lg:leading-[4rem] mb-2">
            Subscribe to our Newsletter
          </label>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status }) => (
              <div className="flex flex-row justify-center items-center">
                <input
                  type="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder="Your email"
                  className={`mt-2 placeholder:text-gray-800 flex h-14 w-[90%] items-center justify-center rounded-xl border bg-white/0 px-6 py-7 text-base outline-none ${error ? "border-red-800 border-2 animate-pulse" : "border-gray-700"}`}
                />
                <button
                  disabled={status === "sending" || !email || error}
                  onClick={() => subscribe({ EMAIL: email })}
                  className={`py-[0.75rem] ml-2 px-2 h-14 w-[12rem] mt-1 text-center items-center text-base font-semibold rounded-lg border border-transparent bg-indigo-950 text-white hover:bg-indigo-600 disabled:opacity-90 disabled:bg-indigo-800 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 transition-all ease-in-out duration-500 tracking-widest ${status === "sending" ? "text-yellow-500" : "text-white"}`}
                >
                  {status === "sending" ? "Sending..." : "Subscribe"}
                </button>
              </div>
            )}
          />
        </div>
        <div className="flex flex-col lg:flex-row lg:space-y-0 space-y-4 lg:space-x-20 lg:px-0 px-5 lg:mt-0 mt-12">
          <div className="space-y-2 flex flex-col">
            <p className="text-xl font-bold text-gray-700 tracking-wide leading-[4rem] lg:mb-2">
              Resources
            </p>
            {/* <a
              href="https://docs.mettalex.com/docs/MettalexOverview"
              className="text-gray-700 hover:text-gray-600 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Docs
            </a>
            <a
              href="https://docs.mettalex.com/blog/update_january"
              className="text-gray-700 hover:text-gray-600 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Blog
            </a> */}
            <a
              href="https://docs.mettalex.com/docs/MTLXToken/MTLXTokennomics"
              className="text-gray-700 hover:text-gray-600 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Tokenomics
            </a>
            <a
              href="https://docs.mettalex.com/docs/mtlxtoken/mtlxtokenutility/"
              className="text-gray-700 hover:text-gray-600 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Utility
            </a>
            <a
              href="https://docs.mettalex.com/resources/roadmap"
              className="text-gray-700 hover:text-gray-600 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Roadmap
            </a>
          </div>

          <div className="space-y-2 flex flex-col">
            <h5 className="text-xl font-bold text-gray-700 tracking-wide leading-[4rem] lg:mb-2">
              Company
            </h5>
            {/* <div className="flex items-center space-x-2">
              <Link
                to="/aboutus"
                className="text-gray-700 hover:text-gray-600 hover:underline"
              >
                About us
              </Link>
            </div>
            <div className="flex items-center space-x-2">
              <Link
                to="/careers"
                className="text-gray-700 hover:text-gray-600 hover:underline"
              >
                Careers
              </Link>
            </div> */}
            <div
              onClick={handleScrollToTop}
              className="flex items-center space-x-2"
            >
              <Link
                to="/about"
                className="text-gray-700 hover:text-gray-600 hover:underline"
              >
                About
              </Link>
            </div>
            <div
              onClick={handleScrollToTop}
              className="flex items-center space-x-2"
            >
              <Link
                to="https://wellfound.com/company/mettalex_dex"
                className="text-gray-700 hover:text-gray-600 hover:underline"
                target="_blank"
              >
                Join Us
              </Link>
            </div>
          </div>

          {/* <div className="space-y-2 flex flex-col">
            <p className="text-xl font-bold text-gray-700 tracking-wide leading-[4rem] lg:mb-2">
              Legal
            </p>
            <div className="flex items-center space-x-2">
              <a
                href="/privacy-policy"
                className="text-gray-700 hover:text-gray-600 hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
            <div className="flex items-center space-x-2">
              <a
                href="/terms-of-condition"
                className="text-gray-700 hover:text-gray-600 hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                Terms and condition
              </a>
            </div>
          </div> */}
        </div>

        {/* <div className="text-xl font-bold text-black tracking-wide leading-[4rem] mb-2 lg:px-0 px-5 lg:mt-0 mt-[1.5rem]">
          <p>Follow Us</p>
          <button
            type="button"
            className="py-3 px-5 inline-flex items-center gap-x-2 text-base font-semibold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-indigo-800 hover:text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 transition-all ease-in-out duration-500 tracking-widest"
          >
            Community
          </button>
        </div> */}
      </div>

      <div className="text-center text-[14px] font-[500] mt-12 mb-3 flex flex-col  justify-center space-y-2">
        © 2024 Mettalex. All Rights Reserved. Website maintained by the
        Mettalex Team.
        <p>
          For inquiries contact us at
          <a
            href="mailto:info@mettalex.com"
            className="font-bold hover:hover:text-blue-900 ml-[0.1rem]"
            rel="noreferrer"
          >
            info@mettalex.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
