import { useCallback, useEffect, useMemo, useState } from "react";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { GasPrice, SigningStargateClient } from "@cosmjs/stargate";

import { initKeplr } from "./keplr";
import {
  CHAIN_ID,
  GAS_PRICE,
  RPC_ENDPOINT,
  STAKING_DENOM,
} from "./constants";

export function NetworkContext() {
  const [wallet, connect] = useWallet();
  const cwClient = useSigningClient(wallet.signer);
  const sgClient = useSigningStargateClient(wallet.signer);
  const balance = WalletBalance(wallet.address, sgClient);

  const context = useMemo(
    () => ({
      wallet,
      sgClient,
      cwClient,
      balance,
    }),
    [balance, cwClient, sgClient, wallet]
  );
  return [context, connect];
}

const initialWalletState = {
  connected: false,
  message: "",
};

export function useWallet() {
  const [wallet, setWallet] = useState<any>(initialWalletState);

  const connectKeplr = useCallback(
    async (shouldSuggestChain: any, walletType: any) => {
      const keplr: any = await initKeplr(shouldSuggestChain, walletType);
      if (keplr?.length > 0) {
        setWallet({ message: keplr });
      } else {
        const key = await keplr?.getKey(CHAIN_ID);

        if (key) {
          setWallet({
            keplr,
            connected: !!keplr,
            signer: keplr?.getOfflineSigner(CHAIN_ID),
            address: key?.bech32Address,
          });
        }
      }
    },
    [setWallet]
  );

  return [wallet, connectKeplr];
}

export function useSigningClient(signer: any) {
  const [client, setClient] = useState<any>();

  useEffect(() => {
    if (typeof signer === "undefined") {
      return undefined;
    }

    try {
      SigningCosmWasmClient.connectWithSigner(RPC_ENDPOINT, signer).then(
        setClient
      );
    } catch (e) {
      console.log(e);
    }
  }, [signer]);

  return client;
}

export function useSigningStargateClient(signer: any) {
  const [client, setClient] = useState<any>();
  useEffect(() => {
    if (typeof signer === "undefined") {
      return;
    }
    try {
      const gasPrice = GasPrice.fromString(GAS_PRICE + STAKING_DENOM);
      SigningStargateClient.connectWithSigner(RPC_ENDPOINT, signer, {
        gasPrice,
      }).then(setClient);
    } catch (e) {
      console.log(e);
    }
  }, [setClient, signer]);

  return client;
}

export function WalletBalance(address: any, client: any) {
  const [balance, setBalance] = useState<any>({
    amount: "",
    denom: STAKING_DENOM,
  });

  useEffect(() => {
    if (typeof address === "undefined" || address === "") {
      return;
    }

    if (typeof client === "undefined") {
      return;
    }
    client
      .getBalance(address, STAKING_DENOM)
      .then(setBalance)
      .catch((error: any) => {
        console.log(error);
      });
  }, [address, client]);

  return balance;
}
