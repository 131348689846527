/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from "react";
import Header from "./component/Header";
import LandingSection from "./component/landingpage";
import Product from "./component/productpage";
import ExplorePage from "./component/explorepage";
import Footer from "./component/footer";
import Community from "./component/communitypage";
import CookiesBannerConsent from "./component/cookieconsent";
import { products, communityItems, blogData, subBlogsData } from "./data";
import MailChimp from "./component/mailchimp";
import Proposel from "./component/proposel";
import AnyMtlxPopOver from "./component/anymtlxPopover";

function Home() {
  const [scrollPosition, setScrollPosition] = useState(0);
  // const [showScrollUp, setShowScrollUp] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(true);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // const scrollUp = () => {
  //   window.scrollTo({
  //     top: scrollPosition - 600,
  //     behavior: "smooth",
  //   });
  // };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // setShowScrollUp(scrollPosition > 0);
    const isBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;
    setShowScrollDown(!isBottom);
    if (scrollPosition > 5) {
      setShowScrollDown(false);
    }
  }, [scrollPosition]);

  const scrollDown = () => {
    window.scrollTo({
      top: scrollPosition + 600,
      behavior: "smooth",
    });
  };

  window.onscroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  return (
    <div className="">
      {/* <Proposel /> */}
      {open && <AnyMtlxPopOver open={open} handleClose={handleClose} />}
      <Header setOpen={setOpen}/>
      {/* <CookiesBannerConsent /> */}
      <div className="fixed hidden flex-row bottom-[3%] left-[50%] transform -translate-y-1/2 -translate-x-1/2 z-[1]">
        <div className="fixed flex h-[5rem] w-[3.5rem]  z-1000 opacity-80  inset-y-1/2 left-1/2 transform -translate-y-1/2  -translate-x-1/2 z-10">
          {/* {showScrollUp && (
            <img
              onClick={scrollUp}
              className="px-4 py-2 text-white mr-2 cursor-pointer"
              src="images/caret-up-icon.svg"
              alt="up"
              width={100}
              height={100}
            />
          )} */}
          {showScrollDown && (
            <img
              onClick={scrollDown}
              className="max-w-[100px] h-full cursor-pointer"
              src="images/angle-down.png"
              alt="down"
            />
          )}
        </div>
      </div>
      <div className="relative">
        <img
          className=" w-full md:h-[37rem] lg:h-[55rem] absolute z-[-9999] md:flex hidden"
          src="images/Top2.png"
          alt="headerimage"
        />
        <LandingSection />
        {/* <div className="flex flex-col mx-auto justify-center items-center  md:px-5 lg:px-0 mb-[11rem] w-[95%]">
          <label className="text-base text-indigo-600 md:text-[2.5rem] md:px-0 lg:text-[2.8rem] font-bold tracking-wide lg:leading-[4rem] mb-2">
            Subscribe to our Newsletter
          </label>
          <MailChimp />
        </div> */}
        <div className="flex flex-col mx-auto justify-center items-center  md:px-5 lg:px-0 mb-[11rem] w-[95%]">
          {/* <div className="m-auto"> */}
          <iframe
            className="w-[400px] h-[300px] md:w-[760px] md:h-[500px] lg:w-[1000px] lg:h-[600px]"
            loading="lazy"
            src="https://www.youtube.com/embed/cmn7Svf_kmk?si=JIUqISkkRKbEIdYa"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        {/* </div> */}
      </div>

      {products.map((product, index) => (
        <Product key={index} {...product} />
      ))}
      <ExplorePage blogs={blogData} subBlogs={subBlogsData} />
      <Community communityItems={communityItems} />
      <div className="relative">
        <img
          className="w-[100%] h-[45rem] bottom-0 absolute z-[-9999] md:flex hidden"
          src="images/foot.png"
          alt="Footer"
        />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
